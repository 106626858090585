const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://didrive.co' : 'http://192.168.100.106:3038',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://didrive.co' : 'http://192.168.100.106:3038',
    api: 'api/',
    apiSocket: 'didrive::1.2',
    nameDir: 'didrive',
    package: 'co.didrive.web',
    version: '1.0.1',
    googleAPIKey: 'AIzaSyDwGCJLBKnhzKfeaxhb2EXXdwd6mxISH5I',
    appName: 'DiDrive',
    provider: '',
    colorPrimary: '#34D300',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'co.didrive.passenger',
    playStoreDriverId: 'co.didrive.driver',
    appStorePassengerId: '6502960261',
    appStoreDriverId: '6502960149',
    email: "contactocali@didrive.co",
};
export default config;
